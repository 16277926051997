<template>
  <main>
    <h1>{{ $t("livestream_title") }}</h1>
    <button @click="getSignature">Join Meeting</button>
  </main>
</template>

<script>
import axios from "@/plugins/axios.plugin";
import { ZoomMtg } from "@zoomus/websdk";

export default {
  name: "ZoomPlayer",
  created() {
    ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.1/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();
  },
  mounted() {
    ZoomMtg.inMeetingServiceListener("onUserJoin", (data) => {
      console.log("inMeetingServiceListener onUserJoin", data);
    });
  },
  data() {
    return {
      role: 0,
      signatureEndpoint:
        process.env.VUE_APP_API_URL + "/generate-zoom-signature.php",
    };
  },
  methods: {
    getSignature() {
      axios
        .post(this.signatureEndpoint)
        .then((res) => {
          console.log("signature" + res.data.signature);
          this.startMeeting(res.data.signature);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    startMeeting(signature) {
      document.getElementById("zmmtg-root").style.display = "block";

      ZoomMtg.init({
        leaveUrl: this.livestream.leaveUrl,
        isSupportAV: true,
        success: (success) => {
          console.log(success);
          ZoomMtg.join({
            meetingNumber: this.livestream.code[this.locale],
            userName: this.currentUser.username,
            signature: signature,
            apiKey: this.livestream.api_key,
            userEmail: this.zoomUser.email,
            passWord: this.livestream.password,
            success: (success) => {
              console.log(success);
            },
            error: (error) => {
              console.log(error);
            },
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale;
    },
    livestream() {
      return this.$store.getters["Livestreams/active"];
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
main {
  width: 70%;
  margin: auto;
  text-align: center;
}

main button {
  margin-top: 20px;
  background-color: #2d8cff;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #2681f2;
}
</style>
